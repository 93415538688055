<template>
    <path d="m416 176h-144v-144c0-17.7-14.3-32-32-32h-32c-17.7 0-32 14.3-32 32v144h-144c-17.7 0-32 14.3-32 32v32c0 17.7 14.3 32 32 32h144v144c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32v-144h144c17.7 0 32-14.3 32-32v-32c0-17.7-14.3-32-32-32z"/>
</template>

<script>
export default {
    setup(props, { emit }) {
        emit('dimensions', { width: 448, height: 448 });
    },
    emits: ['dimensions'],
};
</script>
